import { useContext, useEffect, useState, useRef } from 'react';
import { Context } from '../../../context/store';
import { post } from '../../../api/requests/main';
import * as total from "../../../helpers/getTotal";
import { maskPhone } from "../../../helpers/general";
import ReCheckin from '../../../pages/public/re-Checkin';
import MoveBooking from '../../../pages/public/MoveBooking';
import ViewUserInfo from '../../../pages/public/ViewUserInfo';
import { formatTimestamp } from '../../../helpers/timeFormat';
import Channel from '../../../pages/components/platform';

import { toast } from 'react-toastify';


import CheckIn from '../../../pages/public/checkin';
import { ExtraCharge, SubMethod } from '../../../pages/sub-componments/paymentMethod';
import Stripe from '../../../pages/components/stripe';
import { Notification } from '../logic/Toggles';
import AddNewPayment from './sub/addNewPayment';
import AddNewDeposit from './sub/addNewDeposit';
import CreditCard from './sub/creditCard';
import AddNewExtraCharge from './sub/addNewExtraCharge';
import Rooms from '../../../pages/sub-componments/Rooms';
import BookStatus from '../../../pages/sub-componments/Bookstatus';
import { country } from '../../../helpers/getCountry';

function ViewBooking({ bid, mainState }) {
  const [_, setMainState] = useContext(Context);
  const [booking, setBooking] = useState(false);
  const [days, setDays] = useState(false);
  const [user, setUser] = useState(false);
  const [door, setDoor] = useState(false);
  const [uid, setUID] = useState(false);
  const [editNotes, setEditNotes] = useState(false);


  const [newPayment, setnewPayment] = useState(false);
  const [newExtraCharge, setnewExtraCharge] = useState(false);
  const [newDeposit, setnewDeposit] = useState(false);


  const [stripePopup, setStripePopup] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [newBooking, setNewBooking] = useState(null);
  const [moveBooking, setMoveBooking] = useState(null);
  const [checkPopup, setCheckPopup] = useState(false);
  const [bookingState, setBookingState] = useState(false);
  const [bDate, setBDate] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [amount, setAmount] = useState('');

  const amountRef = useRef();
  const PaymentRef = useRef("");
  const ExtraChargeRef = useRef("");
  const DepositRef = useRef("");
  const textareaNote = useRef("");

  const [activeTab, setActiveTab] = useState('General');

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getBookingInfo();
  }, []);


  const addPayment = (a) => {
    setnewPayment(true);
  };
  const addExtraCharge = (a) => {
    setnewExtraCharge(true);
  };
  const addDeposit = (a) => {
    setnewDeposit(true);
  };
  const saveDeposit = async (a) => {
    a.preventDefault();
    setMainState({ type: "loader", payload: true });
    if (
      DepositRef.current.amount.value ||
      DepositRef.current.amount.value !== ""
    ) {
      try {
        const response = await post("deposit-add", DepositRef.current);
        if (response.status === 201 || response.status === 200) {
          toast('New Deposit added!');
          setnewDeposit(false);
          // setMainState({ type: "bookingId", payload: false });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setMainState({ type: "loader", payload: false });
      }
    }
  };


  const getBookingInfo = async () => {
    setMainState({ type: "loader", payload: true });

    try {
      const response = await post("get-booking-info", { id: bid });
      if (response.status === 200) {
        setBooking(response.data.data.booking);
        setDays(response.data.data.bookingDate);
        setUser(response.data.data.user);
        setUID(response.data.data.userId);
        setDoor(response.data.data.door);
        setBDate(response.data.data.bookingDate);
        setBookingState(response.data.data.booking.bookingStatus)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setMainState({ type: "loader", payload: false });
    }
  };


  const resendEmail = async (a) => {
    a.preventDefault();

    if (mainState.loader) { return }
    setMainState({ type: "loader", payload: true });
    a.target.disabled = true;
    a.target.innerHTML = "...";
    try {
      const response = await post("verify-email", { id: uid });
      if (response.status === 200) {
        a.target.innerHTML = "Sent";
        a.target.classList.add("done");
      }
    } catch (error) {
      console.error(error);
      a.target.innerHTML = "err";
      a.target.disabled = false;
    } finally {
      setMainState({ type: "loader", payload: false });
    }
  };

  const showPop = (a) => { if (a) { setCheckPopup(true); } else { setCheckPopup(false); } }


  const saveExtraCharge = async (a) => {
    a.preventDefault();

    if (mainState.loader) { return }
    setMainState({ type: "loader", payload: true });
    if (
      ExtraChargeRef.current.amount.value ||
      ExtraChargeRef.current.amount.value !== ""
    ) {
      try {
        const response = await post("payment-add", ExtraChargeRef.current);
        if (response.status === 201 || response.status === 200) {
          toast("New Payment added!");
          setnewExtraCharge(false);
          // setMainState({ type: "bookingId", payload: false });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setMainState({ type: "loader", payload: false });
      }
    }
  };

  const savePayment = async (a) => {
    a.preventDefault();

    if (mainState.loader) { return; }
    setMainState({ type: "loader", payload: true });

    if (
      PaymentRef.current.amount.value ||
      PaymentRef.current.amount.value !== ""
    ) {
      try {
        const response = await post("payment-add", PaymentRef.current);
        if (response.status === 201 || response.status === 200) {
          toast("New Payment added!");
          setnewPayment(false);
          // setMainState({ type: "bookingId", payload: false });
        }
        setMainState({ type: "loader", payload: false });
      } catch (error) {
        console.error(error);
        setMainState({ type: "loader", payload: false });
      }
    }
  };


  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const moveAllBed = async (a) => {
    setMainState({ type: "loader", payload: true });
    let bedNumber = a.target.parentElement.querySelector("select").value;
    try {
      const response = await post("move-booking-date-all", {
        id: bid,
        bedNumber: bedNumber,
      });
      if (response.status === 201) {
        toast("Booking Has been Moved!");
        // setMainState({ type: "bookingId", payload: false });
      }
    } catch (error) {
    } finally {
      setMainState({ type: "loader", payload: false });
    }
  };

  const saveBooking = async (a) => {
    setMainState({ type: "loader", payload: true });
    let elm = a.target.parentElement.parentElement.closest(".bookin");
    let bedNumber = elm.querySelector("[name=bedNumber]").value;
    let amount = elm.querySelector("[name=amount]").value;
    let bookingDate = elm.querySelector("[name=bookingDate]").value;
    let id = elm.querySelector("[name=id]").value;
    setMainState({ type: "loader", payload: true });
    try {
      const response = await post("update-booking-date", {
        id: id,
        bedNumber: bedNumber,
        bookingDate: bookingDate,
        amount: amount,
      });
      if (response.status === 201) {
        toast("Booking Updated!");
      }
    } catch (error) {
    } finally {
      setMainState({ type: "loader", payload: false });
    }
  };
  const deleteBooking = async (a) => {
    setMainState({ type: "loader", payload: true });
    let elm = a.target.closest(".btn-grb.mn");
    // let elm = a.target.parentElement.parentElement.parentElement
    let id = elm.parentElement.querySelector("[name=id]").value;
    try {
      const response = await post("delete-booking-date", { id: id });
      if (response.status === 201) {
        toast("Booking is Deleted!");
      }
      elm.parentElement.classList.add("no-h");
    } catch (error) {
    } finally {
      setMainState({ type: "loader", payload: false });
    }
  };
  const saveBookingInfo = async (e) => {
    setMainState({ type: "loader", payload: true });

    try {
      const response = await post("update-booking-info", { id: bid, bookingStatus: bookingState });
      if (response.status === 201) {
        toast(response.data.message);
        // setMainState({ type: 'bookingId', payload: false })
      }
    } catch (error) {
    } finally {
      setMainState({ type: "loader", payload: false });
    }
  };

  const saveNotes = async (e) => {
    setMainState({ type: "loader", payload: true });
    try {
      let payload = {
        id: bid,
        description: textareaNote.current.value,
      }
      const response = await post("update-description", payload);
      if (response.status === 201) {
        toast(response.data.message);
      }
    } catch (error) {
    } finally {
      setEditNotes(false)
      setMainState({ type: "loader", payload: false });
    }
  };
  const newdoorCode = async (e) => {
    if (mainState.loader) { return }
    setMainState({ type: "loader", payload: true });
    door.code = ''
    setDoor(door)
    try {
      const response = await post("request-new-door-access", {
        id: bid
      });
      if (response.status === 201) {
        toast(response.data.message);
      }
    } catch (error) {
    } finally {
      setMainState({ type: "loader", payload: false });
      setMainState({ type: 'bookingId', payload: false })
    }
  };
  if (!booking) {
    return <>
      <div className='popup'>
        <div className='child wide'>
          Loading
        </div>
      </div>
    </>
  }

  return (
    <>
      <style> {` body { overflow:hidden } `} </style>
      <div className='side-popup'>
        <div className='child wide'>
          <header className='flex'>
            <span onClick={() => { setMainState({ type: 'bookingId', payload: false }) }}><i className="fa-solid fa-xmark"></i></span>
            <h4>Booking Info <small>{days.length} Night</small>
              <a className="a" onClick={() => { showPop(true); }} > {user.signature ? 'Re-Check in' : 'Check in Guest'} </a>
            </h4>
          </header>
          <section>
            <div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className={`nav-link ${activeTab === 'General' ? 'active' : ''}`} href="#" onClick={() => handleTabClick('General')} aria-current={activeTab === 'General' ? 'page' : undefined} >
                    General
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${activeTab === 'Booking' ? 'active' : ''}`} href="#" onClick={() => handleTabClick('Booking')} >
                    Payments
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className={`content ${activeTab === 'General' ? '' : 'hidden'}`}>
                  <h3>Guest Information </h3>
                  {/* <a className="a btn" onClick={() => { showPop(true); }} > {user.signature ? 'Re-Check in' : 'Check in Guest'} </a> */}
                  <div className='flex'>
                    <span> Name: <b className='a' onClick={() => { setMainState({ type: 'userId', payload: uid }) }}> {user.name} {user.lastname} </b> </span>
                  </div>
                  <div className='flex'>
                    <span> Phone:  {maskPhone(user.phone)} </span>
                  </div>
                  <div className='flex'>
                    <span> Nationality:  {country(user.nationality)}  </span>
                  </div>
                  <div className='flex' > Email: <> {user.email ?
                    <> {user.email} <span className={user.verified ? "verified" : "pending"}> {user.verified ? "verified" : ""} </span> {user.verified ? (<></>) : (<button className="a" onClick={resendEmail}> Send verification email </button>)} </>
                    : <></>}
                  </> </div>
                  <hr />
                  <div className='flex' style={{ margin: '0' }}>
                    <h3>Booking Details  {booking.promotional_code ? <span className='Discounted'>discounted</span> : false}</h3>
                    {mainState?.user?.is_staff && (<CreditCard id={booking.id} />)}
                  </div>
                  <>
                    <div className='flex-one'> Source: <Channel name={bDate[0].platform} /></div>
                    <div className='noteWrapper' >
                      <div className=" space sw">
                        {editNotes ?
                          <div className='wrp'>
                            <b className="a save" onClick={saveNotes}>
                              <i className="fas fa-save"></i> Save
                            </b>
                          </div>
                          :
                          <div className='wrp'>
                            <b className="a" onClick={() => { setEditNotes(true) }}>
                              <i className="fas fa-edit"></i> Edit
                            </b>
                          </div>
                        }

                        <div>
                          Notes:
                          <textarea className="form-control" disabled={!editNotes} ref={textareaNote} defaultValue={booking.description}></textarea>
                        </div>
                      </div>
                    </div>
                    {mainState?.user?.is_staff ? (
                      <div className='flex-select'>
                        <span> Status: </span> <div className="drop_save">
                          <select
                            defaultValue={booking.bookingStatus}
                            onChange={(s) => setBookingState(s.target.value)}
                            className="form-select"
                            name="bookingStatus"
                          >
                            <BookStatus />
                          </select>
                          <input type="hidden" defaultValue={bid} name="id" />
                          <button className="btn btn-primary" onClick={saveBookingInfo}>
                            Save
                          </button>
                        </div>
                      </div>
                    ) : <div className='flex-select'>
                      <span> Status: </span> <p><span className={` color_not ${booking.bookingStatus}`}>{booking.bookingStatus}</span></p>
                    </div>}

                      {/* <div className='flex-select'>
                        <span> Bed: </span>
                        <div className="drop_save">
                          <input type="hidden" defaultValue={bid} name="id" />
                          <button className="btn btn-primary" onClick={moveAllBed}>
                            Save
                          </button>
                        </div>
                      </div> */}
                    <div className='flex'>

                      <div className='btn btn-success main btn-sm'
                        onClick={() => {
                          setNewBooking(true);
                        }}>
                        New Booking
                      </div>
                      {/* <div className='btn btn-primary secondary btn-sm'
                        onClick={() => {
                          setMoveBooking(true);
                        }}
                      >
                        Move Booking
                      </div> */}
                    </div>
                    <div className="accordion-main">
                      <input type="checkbox" name="seprate" id="seprate" />
                      <label htmlFor="seprate"> seprate edit </label>
                      <div>
                        {bDate.map((a) => {
                          return (
                            <div className="bookin" key={a.id}>
                              <span>
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  name="amount"
                                  readOnly={!mainState?.user?.is_staff}
                                  defaultValue={a.amount}
                                  className="sm form-control"
                                />
                              </span>
                              <span>

                                <input
                                  type="date"
                                  defaultValue={a.bookingDate}
                                  name="bookingDate"
                                  className="sm form-control"
                                />
                              </span>
                              <input type="hidden" defaultValue={a.id} name="id" />
                              <div className="btn-grb mn">
                                <span className="btn save" onClick={saveBooking}>
                                  <i className="far fa-save"></i>
                                </span>
                                {mainState?.user?.is_staff && (
                                  <>
                                    <span className="btn delete" onClick={deleteBooking}>

                                      <i className="fas fa-trash-alt"></i>
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                  </>


                </div>
                <div className={`content ${activeTab === 'Booking' ? '' : 'hidden'}`}>

                  {booking ?
                    <>

                      <h3> Financial Overview </h3>
                      <div className="flex"> Payments Received: <b>{Number(total.payment(user.payments)).toFixed(2)}</b> </div>
                      <div className="flex"> Booking Fees: <b>{Number(total.booking(user.bookings)).toFixed(2)}</b> </div>
                      <div className="flex"> Deposit Payments: <b>{Number(total.deposit(user.deposits)).toFixed(2)}</b> </div>
                      <div className="flex"> Extra Services Fees: <b>{Number(total.payment(user.others)).toFixed(2)}</b> </div>
                      <div className='flex footer'>
                        Total Outstanding Balance:
                        <b >
                          <span className={Number(total.calc(user.payments, user.bookings)).toFixed(2) < 0 ? "red" : ""}>
                            {Number(total.calc(user.payments, user.bookings)).toFixed(2)}
                          </span>
                          {Number(total.calc(user.payments, user.bookings)).toFixed(2) < 0 ?
                            <small> ( POS: {(Number(total.calc(user.payments, user.bookings)) * 1.05).toFixed(2)} ) </small>
                            : false}
                        </b>
                      </div>
                      <hr />
                      <h3> Payment Options </h3>
                      <div className='flex'>
                        {mainState?.user?.is_staff ? (
                          <>
                            <p className="btn btn-light main btn-sm Payment" onClick={addPayment} > + Payment </p>
                            <p className="btn btn-light main btn-sm Deposit" onClick={addDeposit} > + Deposit </p>
                            <p className="btn btn-light main btn-sm Charge" onClick={addExtraCharge} > + Extra Charge </p>
                          </>
                        ) : (
                          <>
                            <p className="btn btn-light main btn-sm Deposit" onClick={addDeposit} > + Deposit </p>
                          </>
                        )}
                      </div>


                    </>

                    : <></>}

                </div>
              </div>
            </div>
          </section>

        </div >
      </div >


      {
        newExtraCharge ? (
          <AddNewExtraCharge userid={mainState.user.id} bid={bid} uid={uid} setStripePopup={setStripePopup} setnewExtraCharge={setnewExtraCharge} ExtraChargeRef={ExtraChargeRef} ExtraCharge={ExtraCharge} SubMethod={SubMethod} saveExtraCharge={saveExtraCharge} />
        ) : (
          <></>
        )
      }
      {
        newDeposit ? (
          <AddNewDeposit setnewDeposit={setnewDeposit} SubMethod={SubMethod} mainState={mainState} uid={uid} saveDeposit={saveDeposit} DepositRef={DepositRef} />
        ) : (
          <></>
        )
      }

      {
        newBooking ? (
          <div className="popup">
            <div className="child">
              <header className='flex'>
                <h4>Re-checkin </h4>
                <span onClick={() => { setNewBooking(false) }}><i className="fa-solid fa-xmark"></i></span>
              </header>
              <ReCheckin
                userId={uid}
                bDate={bDate}
                popup={setNewBooking}
              />
            </div>
          </div>
        ) : (
          <></>
        )
      }
      {
        moveBooking ? (
          <div className="popup">
            <div className="child">
              <MoveBooking userId={uid} user={user} bDate={bDate} popup={setMoveBooking} />
            </div>
          </div>
        ) : (
          <></>
        )
      }
      {
        userInfo ? (
          <div className="popup">
            <div className="child large uinfo">
              <span onClick={() => setUserInfo(false)}>x</span>
              <ViewUserInfo data={userInfo} state={mainState} popup={setUserInfo} />
            </div>
          </div>
        ) : (
          <></>
        )
      }
      {
        stripePopup ? (
          <div className="popup stripe">
            <div className="child large">
              <header className='flex'>
                <h4> New Payment Strip link Generator </h4>
                <span onClick={() => { setStripePopup(false) }}><i className="fa-solid fa-xmark"></i></span>
              </header>
              <Stripe uid={mainState.user.id} gid={uid} bid={bid} type={stripePopup} popup={setUserInfo} />
            </div>
          </div>
        ) : (
          <></>
        )
      }
      {
        checkPopup ? (
          <div className="popup">
            <div className="child checkpop">
              <CheckIn gid={uid} showPop={showPop} user={user} />
            </div>
          </div>
        ) : (
          <></>
        )
      }
      {
        newPayment ? (
          <AddNewPayment
            amount={amount}
            bid={bid}
            handlePaymentChange={handlePaymentChange}
            SubMethod={SubMethod}
            paymentMethod={paymentMethod}
            mainState={mainState}
            uid={uid}
            savePayment={savePayment}
            setnewPayment={setnewPayment}
            setStripePopup={setStripePopup}
            PaymentRef={PaymentRef}
            amountRef={amountRef}
            handleAmountChange={handleAmountChange} />
        ) : (
          <></>
        )
      }
    </>
  );
}

export default ViewBooking;
